import React, { useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import { CategoryResponse } from './types';
import ApiService from './api';
import { EditIcon, TrashIcon } from 'lucide-react';

const CategoryCard = ({ category, fetchData, onEdit }: { category: CategoryResponse, fetchData: any, onEdit: any }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const handleNext = () => {
        setCurrentImageIndex((prev) => (prev + 1) % category.images.length);
    };

    const handlePrevious = () => {
        setCurrentImageIndex((prev) => (prev - 1 + category.images.length) % category.images.length);
    };

    return (
        <div className="p-2">
            <div className="w-[400px] rounded-lg shadow-md overflow-hidden">
                <div className="relative h-[200px]">
                    {category.images.length > 0 ? (
                        <img
                            src={ApiService.getFileUrlByID(category.images[currentImageIndex])}
                            alt={category.name}
                            className="w-full h-full object-cover rounded border border-gray-200"
                        />
                    ) : (
                        <div className="w-full h-full bg-gray-200 rounded border border-gray-200 flex items-center justify-center">
                            <span className="text-gray-500">No Image Available</span>
                        </div>
                    )}
                    {category.images.length > 1 && (
                        <div className="absolute inset-x-0 bottom-0 flex justify-between p-2">
                            <button onClick={handlePrevious} className="bg-black/50 text-white p-1 rounded">
                                &lt;
                            </button>
                            <button onClick={handleNext} className="bg-black/50 text-white p-1 rounded">
                                &gt;
                            </button>
                        </div>
                    )}

                </div>
                <div>
                    <h2 className="text-lg font-semibold">{category.name}</h2>
                    <p className="mt-2 text-sm text-gray-700">{category.description}</p>
                </div>

                <div className="p-4">
                    <div className="p-2 border-t flex justify-between">
                        <button onClick={() => onEdit(category)} className="p-2 text-blue-500">
                            <EditIcon size={18} />
                        </button>
                        <button onClick={() => setIsDeleteDialogOpen(true)} className="p-2 text-red-500">
                            <TrashIcon size={18} />
                        </button>
                    </div>
                </div>


                <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
                    <DialogContent>
                        <p>Are you sure you want to delete this category?</p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
                        <Button
                            onClick={() => {
                                ApiService.delete("categories", category.id)
                                setIsDeleteDialogOpen(false);
                                fetchData()
                            }}
                            color="error"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        </div>
    );
}


export default CategoryCard;