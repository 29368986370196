class ApiService {
    static baseUrl = 'https://api.raxitaly.com';
    // static baseUrl = 'http://localhost:8080';
  
    static async getToken() {
      return localStorage.getItem('token');
    }
  
    static async getHeaders() {
      const token = await this.getToken();
      return {
        'Content-Type': 'application/json',
        'Authorization': token || '',
      };
    }
  
    static async login(username, password) {
      try {
        const response = await fetch(`${this.baseUrl}/login`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, password }),
        });
  
        if (response.ok) {
          const data = await response.json();
          localStorage.setItem('token', data.token);
          return true;
        }
        return false;
      } catch (error) {
        console.error('Login error:', error);
        return false;
      }
    }
  
    static async isAuthenticated() {
      try {
        const token = await this.getToken();
        if (!token) return false;
  
        const response = await fetch(`${this.baseUrl}/verify-token`, {
          headers: await this.getHeaders(),
        });
  
        return response.ok;
      } catch (error) {
        console.error('Authentication check error:', error);
        return false;
      }
    }
  
    static async getAll(endpoint) {
      try {
        const response = await fetch(`${this.baseUrl}/${endpoint}`, {
          headers: await this.getHeaders(),
        });
  
        if (response.ok) {
          const data = await response.json();
          console.log(`Data from server for ${endpoint}:`, data);
          return data;
        }
        console.log(`Empty response from server for ${endpoint}`);
        return [];
      } catch (error) {
        console.error(`API call error for ${endpoint}:`, error);
        return [];
      }
    }
  
    static async getOne(endpoint, id) {
      try {
        const response = await fetch(`${this.baseUrl}/${endpoint}/${id}`, {
          headers: await this.getHeaders(),
        });
  
        if (response.ok) {
          return await response.json();
        }
        throw new Error(`Failed to load item from ${endpoint}`);
      } catch (error) {
        console.error(`Error fetching single item from ${endpoint}:`, error);
        return null;
      }
    }
  
    static async create(endpoint, data) {
      try {
        const response = await fetch(`${this.baseUrl}/${endpoint}`, {
          method: 'POST',
          headers: await this.getHeaders(),
          body: JSON.stringify(data),
        });
  
        if (response.ok) {
          return await response.json();
        }
        return null;
      } catch (error) {
        console.error(`Error creating item in ${endpoint}:`, error);
        return null;
      }
    }
  
    static async update(endpoint, id, data) {
      try {
        const response = await fetch(`${this.baseUrl}/${endpoint}/${id}`, {
          method: 'PUT',
          headers: await this.getHeaders(),
          body: JSON.stringify(data),
        });
  
        if (response.ok) {
          return await response.json();
        }
        throw new Error(`Failed to update item in ${endpoint}`);
      } catch (error) {
        console.error(`Error updating item in ${endpoint}:`, error);
        return null;
      }
    }
  
    static async delete(endpoint, id) {
      try {
        const response = await fetch(`${this.baseUrl}/${endpoint}/${id}`, {
          method: 'DELETE',
          headers: await this.getHeaders(),
        });
  
        if (!response.ok) {
          throw new Error(`Failed to delete item from ${endpoint}`);
        }
      } catch (error) {
        console.error(`Error deleting item from ${endpoint}:`, error);
      }
    }
  
    /**
     * Uploads an array of files.
     * @param {File[]} files - Array of files to be uploaded.
     */
    static async uploadFiles(files) {
      try {
        const formData = new FormData();
        files.forEach(file => formData.append('files', file));
  
        const response = await fetch(`${this.baseUrl}/files`, {
          method: 'POST',
          headers: {
            'Authorization': await this.getToken() || '',
          },
          body: formData,
        });
  
        if (response.ok) {
          const data = await response.json();
          return data.ids || [];
        }
        throw new Error('Failed to upload files');
      } catch (error) {
        console.error('File upload error:', error);
        return [];
      }
    }
  
    static getFileUrlByID(id) {
      return `${this.baseUrl}/uploads/${id}`;
    }
  }
  
  export default ApiService;