import { useState } from "react";
import ApiService from "./api";
import { X } from "lucide-react";

export default function FilePicker({
    title,
    files,
    setFiles,
    accept,
    preview,
    multiple = true,
}: {
    files: File[];
    setFiles: any;
    accept?: string;
    title: string;
    preview: boolean;
    multiple?: boolean;
}) {
    const [loading, setLoading] = useState(false);

    const openFilePicker = async () => {
        const input = document.createElement("input");
        input.type = "file";
        input.multiple = multiple;
        input.accept = accept || "image/*";

        input.onchange = async (e: Event) => {
            const target = e.target as HTMLInputElement;
            if (!target.files?.length) return;
            e.preventDefault();
            setLoading(true);

            const selectedFiles = Array.from(target.files);
            try {
                const fileIds = await ApiService.uploadFiles(selectedFiles);
                var files = fileIds.map((fileId: any) => ({
                    id: fileId,
                    name: selectedFiles.find((file) => file.name === fileId)?.name,
                }));
                setFiles((prevFiles: any) => [...prevFiles, ...files]);
            } catch (error) {
                console.error("Error uploading files:", error);
            } finally {
                setLoading(false);
            }
        };
        input.click();
    };

    const removeFileByIndex = (indexToRemove: number) => {
        // @ts-ignore: Unreachable code error
        setFiles(files.filter((_, index) => index !== indexToRemove));
    };

    const getDisplayName = (name: string) => {
        const parts = name.split("-");
        if (parts.length >= 3) {
            return parts[0];
        }
        return name;
    };

    return (
        <div className="space-y-4">
            <button
                onClick={openFilePicker}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            >
                {title}
            </button>

            {loading && (
                <div className="flex justify-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                </div>
            )}

            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {/* @ts-ignore: Unreachable code error */}
                {files.map((file: File, index: number) => (
                    <div key={index} className="relative group mb-4">
                        {preview && (
                            <img
                                src={ApiService.getFileUrlByID(file.id)}
                                alt={file.name}
                                className="w-full h-24 object-cover rounded"
                            />
                        )}
                        <button
                            onClick={() => removeFileByIndex(index)}
                            className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                            <X size={16} />
                        </button>
                        <p className="mt-1 text-sm text-gray-600 truncate">
                            {file.name ?? getDisplayName(file.id)}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export interface File {
    id: string;
    name: string;
}
