import { useState } from "react";
import { useAuth } from "./AuthProvider";

export default function Login() {
    const [input, setInput] = useState({
        username: "",
        password: "",
    });
    const auth = useAuth();

    const handleSubmitEvent = async (e: any) => {
        e.preventDefault();
        if (input.username === "" || input.password === "") {
            return alert("Username or password is empty");
        }
        try {
            auth.loginAction(input.username, input.password);
        } catch (error) {
            alert("Failed to login.")
        }
    };

    const handleInput = (e: any) => {
        const { name, value } = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    return (
        <div className="flex h-screen items-center justify-center bg-gray-100">
            <form
                onSubmit={handleSubmitEvent}
                className="w-full max-w-sm bg-white p-8 rounded-lg shadow-md "
            >
                <h2 className="text-2xl font-semibold text-center text-gray-700 mb-6">
                    Login
                </h2>
                <div className="form_control mb-4">
                    <label htmlFor="username" className="block text-gray-700 font-medium">
                        Username:
                    </label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        placeholder="admin"
                        className="w-full px-4 py-2 mt-2 text-gray-700 bg-gray-100 border border-gray-300 rounded-md focus:border-blue-500 focus:bg-white focus:outline-none"
                        aria-describedby="username"
                        aria-invalid="false"
                        onChange={handleInput}
                    />
                    <div id="username" className="sr-only">
                        Please enter a valid username. It must contain at least 6 characters.
                    </div>
                </div>

                <div className="form_control mb-6">
                    <label htmlFor="password" className="block text-gray-700 font-medium">
                        Password:
                    </label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        className="w-full px-4 py-2 mt-2 text-gray-700 bg-gray-100 border border-gray-300 rounded-md focus:border-blue-500 focus:bg-white focus:outline-none"
                        aria-describedby="user-password"
                        placeholder="****"
                        aria-invalid="false"
                        onChange={handleInput}
                    />
                    <div id="user-password" className="sr-only">
                        Your password should be more than 6 characters.
                    </div>
                </div>

                <button
                    type="submit"
                    className="w-full bg-blue-500 text-white font-semibold py-2 rounded-md hover:bg-blue-600 transition duration-200"
                >
                    Login
                </button>
            </form>
        </div>
    );
}
