import { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Button,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ApiService from "./api";
import { SlideResponse } from "./types";
import FilePicker, { File } from "./FilePicker";


export default function SlideDialog({ open, setOpen, slide, fetchData }: { open: any, setOpen: any, slide: SlideResponse | null, fetchData: any }) {
    const [path, setPath] = useState(slide?.path || "");
    const [imageID, setImage] = useState<File[]>(slide?.image ? [{ id: slide.image, name: "" }] : []);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!path && (imageID.length === 0 || !imageID[0].id)) return;
        const newSlide = {
            name: path,
            image: imageID[0].id,
        };

        try {
            if (slide?.id) {
                await ApiService.update("slides", slide.id, newSlide);
            } else {
                await ApiService.create("slides", newSlide);
            }
            setPath("");
            setImage([]);
            fetchData();
        }
        catch (error) {
            console.error("Error saving slide:", error);
        }
        finally {
            setOpen(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
        >
            <DialogTitle>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => setOpen(false)}
                    aria-label="close"
                    style={{ position: "absolute", right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Path"
                        value={path}
                        onChange={(e) => setPath(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <FilePicker preview={true} title={"Images"} files={imageID} setFiles={setImage} accept="image/*" multiple={false} />
                    <Button type="submit" variant="contained" fullWidth style={{ marginTop: 16 }}>
                        Save
                    </Button>
                </form>
            </DialogContent>
        </Dialog>
    );
}

