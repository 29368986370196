import { useState, useEffect } from 'react';
import ApiService from './api';  // Adjust the import path as needed
import { CategoryResponse, ProductResponse } from './types';
import { useAuth } from './AuthProvider';
import { Navigate } from "react-router-dom";
import ProductCard from './ProductCard';
import ProductDialog from './ProductDialog';
import AddCategory from './CategoryDialog';
import CategoryCard from './CategoryCard';
import SlideDialog from './SlideDialog';
import SlideCard from './SlideCard';

export default function Home() {
    const [activeTab, setActiveTab] = useState('products');
    const [products, setProducts] = useState([]);
    const [slides, setSlides] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [openSlideDialog, setOpenSlideDialog] = useState(false)
    const [openProductDialog, setOpenProductDialog] = useState(false)
    const [openCategoryDialog, setOpenCategoryDialog] = useState(false)

    const [slideToUpdate, setSlideToUpdate] = useState<any | null>(null);
    const [productToUpdate, setPoProductToUpdate] = useState<ProductResponse | null>(null);
    const [categoryToUpdate, setCategoryToUpdate] = useState<CategoryResponse | null>(null);

    useEffect(() => {
        fetchData();
    }, [activeTab]);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            // eslint-disable-next-line default-case
            switch (activeTab) {
                case 'products':
                    const fetchedProducts = await ApiService.getAll('products');
                    setProducts(fetchedProducts);
                    break;
                case 'slides':
                    const fetchedSlides = await ApiService.getAll('slides');
                    setSlides(fetchedSlides);
                    break;
                case 'categories':
                    const fetchedCategories = await ApiService.getAll('categories');
                    setCategories(fetchedCategories);
                    break;
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const auth = useAuth();
    if (!auth.token) return <Navigate to="/login" />;

    const renderContent = () => {

        if (isLoading) {
            return <div className="text-center mt-6">Loading data...</div>;
        }

        switch (activeTab) {
            case 'products':
                return (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">
                        {products && products.map((product: ProductResponse) => (
                            <ProductCard product={product} fetchData={fetchData} onEdit={(product: ProductResponse) => {
                                setPoProductToUpdate(product);
                                setOpenProductDialog(true);
                            }} />
                        ))}
                        {openProductDialog ? <ProductDialog open={openProductDialog} setOpen={setOpenProductDialog} fetchData={fetchData} product={productToUpdate} /> : <></>}
                        <div className='absolute bottom-24 right-10'>
                            <button onClick={() => {
                                setPoProductToUpdate(null);
                                setOpenProductDialog(!openProductDialog);
                            }} className="btn-submit rounded-lg p-2 bg-green-600 text-white text-xl ">
                                Add New Product
                            </button>
                        </div>
                    </div>
                );

            case 'slides':
                return (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">
                        {slides && slides.map((slide: any) => (
                            <SlideCard key={slide.id} slide={slide} fetchData={fetchData} onEdit={(slide: any) => {
                                setSlideToUpdate(slide);
                                setOpenSlideDialog(true);
                            }} />
                        ))}
                        {openSlideDialog ? <SlideDialog open={openSlideDialog} setOpen={setOpenSlideDialog} fetchData={fetchData} slide={slideToUpdate} /> : <></>}
                        <div className='absolute bottom-24 right-10'>
                            <button onClick={() => {
                                setOpenSlideDialog(!openSlideDialog)
                                setSlideToUpdate(null)
                            }} className="btn-submit rounded-lg p-2 bg-green-600 text-white text-xl ">
                                Add New Slide
                            </button>
                        </div>
                    </div>
                );

            case 'categories':
                return (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">
                        {categories && categories.map((category: CategoryResponse) => (
                            <CategoryCard key={category.id} category={category} fetchData={fetchData} onEdit={(category: CategoryResponse) => {
                                setCategoryToUpdate(category);
                                setOpenCategoryDialog(true);
                            }} />
                        ))}
                        {openCategoryDialog ? <AddCategory open={openCategoryDialog} setOpen={setOpenCategoryDialog} fetchData={fetchData} category={categoryToUpdate} /> : <></>}
                        <div className='absolute bottom-24 right-10'>
                            <button onClick={() => {
                                setOpenCategoryDialog(!openCategoryDialog);
                                setCategoryToUpdate(null);
                            }} className="btn-submit rounded-lg p-2 bg-green-600 text-white text-xl ">
                                Add New Category
                            </button>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="p-6">
            <div className="sm:hidden">
                <label htmlFor="Tab" className="sr-only">
                    Tab
                </label>
                <select
                    id="Tab"
                    className="w-full rounded-md border-gray-200 text-xl"
                    value={activeTab}
                    onChange={(e) => setActiveTab(e.target.value)}
                >
                    <option value="products">Products</option>
                    <option value="slides">Slides</option>
                    <option value="categories">Categories</option>
                </select>
            </div>

            <div className="hidden sm:block">
                <div className="border-b border-gray-200 flex">
                    <nav className="-mb-px flex flex-row content-center items-center gap-20 w-full justify-center" aria-label="Tabs">
                        {['products', 'slides', 'categories'].map((tab) => (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a
                                key={tab}
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setActiveTab(tab);
                                }}
                                className={`inline-flex shrink-0 items-center gap-2 border-b-2 px-1 pb-4 text-sm font-medium ${activeTab === tab
                                    ? 'border-sky-500 text-sky-600'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                                    }
                                    text-xl
                                    `}
                            >
                                {/* {tab === 'products' && <AiOutlineProduct />}
                                {tab === 'slides' && <MdSlideshow />}
                                {tab === 'categories' && <MdCategory />} */}
                                {tab.charAt(0).toUpperCase() + tab.slice(1)}
                            </a>
                        ))}
                    </nav>
                </div>
                <div className='absolute bottom-10 right-10'>
                    <button onClick={() => auth.logOut()} className="btn-submit rounded-lg p-2 bg-red-600 text-white text-xl ">
                        Logout
                    </button>
                </div>
            </div>

            {renderContent()}
        </div>
    );
}
