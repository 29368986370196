import React, { useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import { SlideResponse } from './types';
import ApiService from './api';
import { EditIcon, TrashIcon } from 'lucide-react';

const SlideCard = ({ slide, fetchData, onEdit }: { slide: SlideResponse, fetchData: any, onEdit: any }) => {
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    return (
        <div className="p-2">
            <div className="w-[400px] rounded-lg shadow-md overflow-hidden">
                <div className="relative h-[200px]">
                    {slide.image ? (
                        <img
                            src={ApiService.getFileUrlByID(slide.image)}
                            alt={slide.path}
                            className="w-full h-full object-cover rounded border border-gray-200"
                        />
                    ) : (
                        <div className="w-full h-full bg-gray-200 rounded border border-gray-200 flex items-center justify-center">
                            <span className="text-gray-500">No Image Available</span>
                        </div>
                    )}
                </div>

                <div className="p-4">
                    <div className="p-2 border-t flex justify-between">
                        <button onClick={() => onEdit(slide)} className="p-2 text-blue-500">
                            <EditIcon size={18} />
                        </button>
                        <button onClick={() => setIsDeleteDialogOpen(true)} className="p-2 text-red-500">
                            <TrashIcon size={18} />
                        </button>
                    </div>
                </div>

                <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
                    <DialogContent>
                        <p>Are you sure you want to delete this category?</p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
                        <Button
                            onClick={async () => {
                                try {
                                    await ApiService.delete("slides", slide.id);
                                    setIsDeleteDialogOpen(false);
                                    fetchData()
                                } catch (error) {
                                    console.error(error);
                                }
                            }}
                            color="error"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        </div >
    );
}


export default SlideCard;