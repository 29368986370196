import { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Button,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ApiService from "./api";
import { CategoryResponse } from "./types";
import FilePicker, { File } from "./FilePicker";


export default function CategoryDialog({ open, setOpen, category, fetchData }: { open: any, setOpen: any, category: CategoryResponse | null, fetchData: any }) {
    const [name, setName] = useState(category?.name || "");
    const [description, setDescription] = useState(category?.description || "");
    const [imageIDs, setImages] = useState<File[]>(category?.images.map(x => ({ id: x, name: "" })) || []);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!name) return;
        if (imageIDs.length === 0) return;
        const newCategory = {
            name,
            description,
            images: imageIDs.map(x => x.id),
        };

        try {
            if (category?.id) {
                await ApiService.update("categories", category.id, newCategory);
            } else {
                await ApiService.create("categories", newCategory);
            }
            setName("");
            setDescription("");
            setImages([]);
            fetchData();
        }
        catch (error) {
            console.error("Error saving category:", error);
        }
        finally {
            setOpen(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
        >
            <DialogTitle>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => setOpen(false)}
                    aria-label="close"
                    style={{ position: "absolute", right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                        multiline
                        rows={3}
                        margin="normal"
                        required
                    />
                    <FilePicker preview={true} title={"Images"} files={imageIDs} setFiles={setImages} accept="image/*" />
                    <Button type="submit" variant="contained" fullWidth style={{ marginTop: 16 }}>
                        Save
                    </Button>
                </form>
            </DialogContent>
        </Dialog>
    );
}

