import { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Button,
    IconButton,
    MenuItem,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ApiService from "./api";
import { CategoryResponse, ProductResponse } from "./types";
import FilePicker, { File } from "./FilePicker";


export default function ProductDialog({ open, setOpen, product, fetchData }: { open: any, setOpen: any, product: ProductResponse | null, fetchData: any }) {
    const [name, setName] = useState(product?.name || "");
    const [subName, setSubName] = useState(product?.sub_name || "");
    const [description, setDescription] = useState(product?.description || "");
    const [categoryId, setCategoryId] = useState(product?.category_Id || "");
    const [categories, setCategories] = useState<CategoryResponse[]>([]);
    const [imageIDs, setImages] = useState<File[]>(product?.images.map(x => ({ id: x, name: "" })) || []);
    const [fileIDs, setFiles] = useState<File[]>(product?.files.map(x => ({ id: x, name: "" })) || []);
    const [materials, setMaterials] = useState(product?.materials || []);
    const [features, setFeatures] = useState(product?.features || []);
    const [metaData, setMetaData] = useState(product?.metaData || []);
    const [loadingCategories, setLoadingCategories] = useState(false);

    useEffect(() => {
        async function fetchCategories() {
            setLoadingCategories(true);
            const response = await ApiService.getAll("categories");
            setCategories(response);
            setLoadingCategories(false);
            if (!categoryId && response.length > 0) {
                setCategoryId(response[0].id);
            }
        }
        fetchCategories();
    }, []);

    const handleAddMaterial = () => {
        setMaterials([...materials, { name: "", quantity: "" }]);
    };

    const handleAddFeature = () => {
        setFeatures([...features, { name: "", value: "" }]);
    };

    const handleAddMetaData = () => {
        setMetaData([...metaData, { key: "", value: "" }]);
    };

    const handleSubmit = async () => {
        if (!name) return;
        if (imageIDs.length === 0) return;
        if (!categoryId) return;

        const newProduct = {
            name,
            sub_name: subName,
            description,
            category_id: categoryId,
            images: imageIDs.map(x => x.id),
            materials,
            features,
            meta_data: metaData,
            files: fileIDs.map(x => x.id),
        };

        if (product?.id) {
            var response = await ApiService.update("products", product.id, newProduct); // Update product
            if (response) {
                console.log("Product updated successfully");
                setOpen(false);
                fetchData();
            } else {
                console.log("Product update failed");
            }
        } else {
            // eslint-disable-next-line @typescript-eslint/no-redeclare
            var response = await ApiService.create("products", newProduct); // Create new product
            if (response) {
                console.log("Product created successfully");
                setOpen(false);
                fetchData();
            } else {
                console.log("Product creation failed");
            }
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            fullWidth
            disableEscapeKeyDown
        >
            <DialogTitle>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => setOpen(false)}
                    aria-label="close"
                    style={{ position: "absolute", right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div>
                    <TextField
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="Sub Name"
                        value={subName}
                        onChange={(e) => setSubName(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                        multiline
                        rows={3}
                        margin="normal"
                        required
                    />

                    {loadingCategories ? (
                        <CircularProgress />
                    ) : (
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Category</InputLabel>
                            <Select
                                value={categoryId}
                                defaultValue={product?.category_Id}
                                onChange={(e) => setCategoryId(e.target.value)}
                                fullWidth
                            >
                                {categories.map((category) => (
                                    <MenuItem key={category.id} value={category.id}>
                                        {category.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    <FilePicker preview={true} title={"Images"} files={imageIDs} setFiles={setImages} accept="image/*" />
                    <br />
                    <FilePicker preview={false} title={"Files"} files={fileIDs} setFiles={setFiles} accept=".pdf,.docx,.xlsx,.msword,.svg+xml,.pdf" />

                    {/* Materials */}
                    <Button onClick={handleAddMaterial} variant="outlined" fullWidth>
                        Add Material
                    </Button>
                    {materials.map((material: any, index: any) => (
                        <div key={index} style={{ display: "flex", gap: "8px", marginTop: "8px" }}>
                            <TextField
                                label="Material Name"
                                value={material.name}
                                onChange={(e) => {
                                    const newMaterials = [...materials];
                                    newMaterials[index].name = e.target.value;
                                    setMaterials(newMaterials);
                                }}
                                fullWidth
                            />
                            <TextField
                                label="Quantity"
                                value={material.quantity}
                                onChange={(e) => {
                                    const newMaterials = [...materials];
                                    newMaterials[index].quantity = e.target.value;
                                    setMaterials(newMaterials);
                                }}
                                fullWidth
                            />
                        </div>
                    ))}

                    {/* Features */}
                    <Button onClick={handleAddFeature} variant="outlined" fullWidth>
                        Add Feature
                    </Button>
                    {features.map((feature, index) => (
                        <div key={index} style={{ display: "flex", gap: "8px", marginTop: "8px" }}>
                            <TextField
                                label="Feature Name"
                                value={feature.name}
                                onChange={(e) => {
                                    const newFeatures = [...features];
                                    newFeatures[index].name = e.target.value;
                                    setFeatures(newFeatures);
                                }}
                                fullWidth
                            />
                            <TextField
                                label="Feature Value"
                                value={feature.value}
                                onChange={(e) => {
                                    const newFeatures = [...features];
                                    newFeatures[index].value = e.target.value;
                                    setFeatures(newFeatures);
                                }}
                                fullWidth
                            />
                        </div>
                    ))}

                    {/* MetaData */}
                    <Button onClick={handleAddMetaData} variant="outlined" fullWidth>
                        Add Meta Data
                    </Button>
                    {metaData.map((meta, index) => (
                        <div key={index} style={{ display: "flex", gap: "8px", marginTop: "8px" }}>
                            <TextField
                                label="Meta Key"
                                value={meta.key}
                                onChange={(e) => {
                                    const newMeta = [...metaData];
                                    newMeta[index].key = e.target.value;
                                    setMetaData(newMeta);
                                }}
                                fullWidth
                            />
                            <TextField
                                label="Meta Value"
                                value={meta.value}
                                onChange={(e) => {
                                    const newMeta = [...metaData];
                                    newMeta[index].value = e.target.value;
                                    setMetaData(newMeta);
                                }}
                                fullWidth
                            />
                        </div>
                    ))}

                    <Button type="submit" variant="contained" fullWidth style={{ marginTop: 16 }} onClick={handleSubmit}>
                        Save
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}

