import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiService from "./api";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const navigate = useNavigate();

  const loginAction = async (username, password) => {
    try {
      const response = await ApiService.login(username, password)
      if (response) {
        setToken(localStorage.getItem("token") || "")
        navigate("/");
        return;
      }
      throw new Error("login failed");
    } catch (err) {
      alert(err);
    }
  };

  const logOut = async () => {
    await new Promise(resolve => setTimeout(resolve, 500));
    setToken("");
    localStorage.removeItem("site");
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ token, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};


