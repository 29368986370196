import React, { useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import { ProductResponse } from './types';
import { EditIcon, TrashIcon } from 'lucide-react';
import ApiService from './api';

const ProductCard = ({ product, fetchData, onEdit }: { product: ProductResponse, fetchData: any, onEdit: any }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const handleNext = () => {
        setCurrentImageIndex((prev) => (prev + 1) % product.images.length);
    };

    const handlePrevious = () => {
        setCurrentImageIndex((prev) => (prev - 1 + product.images.length) % product.images.length);
    };

    return (
        <div className="p-4">
            <div className="w-full max-w-md rounded-lg shadow-md overflow-hidden bg-white">
                <div className="relative h-48">
                    {product.images.length > 0 ? (
                        <img
                            src={ApiService.getFileUrlByID(product.images[currentImageIndex])}
                            alt={product.name}
                            className="w-full h-full object-cover"
                        />
                    ) : (
                        <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                            <span className="text-gray-500">No Image Available</span>
                        </div>
                    )}
                    {product.images.length > 1 && (
                        <div className="absolute inset-x-0 bottom-0 flex justify-between p-2">
                            <button onClick={handlePrevious} className="bg-black/50 text-white p-1 rounded">
                                &lt;
                            </button>
                            <button onClick={handleNext} className="bg-black/50 text-white p-1 rounded">
                                &gt;
                            </button>
                        </div>
                    )}
                </div>

                <div className="p-4 text-left">
                    <h2 className="text-lg font-semibold">{product.name}</h2>
                    {product.sub_name && <h3 className="text-md text-gray-600">{product.sub_name}</h3>}
                    <p className="mt-2 text-sm text-gray-700">{product.description}</p>

                    {product.materials && (
                        <div className="mt-4">
                            <h4 className="font-semibold">Materials:</h4>
                            <div className="flex flex-wrap gap-2">
                                {product.materials.map((material, index) => (
                                    <span key={index} className="bg-gray-100 px-2 py-1 rounded text-sm">
                                        {material.name}: {material.quantity}
                                    </span>
                                ))}
                            </div>
                        </div>
                    )}

                    {product.features && product.features.length > 0 && (
                        <div className="mt-4">
                            <h4 className="font-semibold">Features:</h4>
                            <ul className="list-disc list-inside">
                                {product.features.map((feature, index) => (
                                    <li key={index} className="text-sm">
                                        {feature.name}: {feature.value}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {product.metaData && (
                        <div className="mt-4">
                            <h4 className="font-semibold">Additional Info:</h4>
                            <ul className="list-none">
                                {product.metaData.map((data, index) => (
                                    <li key={index} className="text-sm">
                                        {data.key}: {data.value}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>

                <div className="p-4 border-t flex justify-between">
                    <button onClick={() => onEdit(product)} className="p-2 text-blue-500">
                        <EditIcon size={18} />
                    </button>
                    <button onClick={() => setIsDeleteDialogOpen(true)} className="p-2 text-red-500">
                        <TrashIcon size={18} />
                    </button>
                </div>
            </div>

            <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
                <DialogContent>
                    <p>Are you sure you want to delete this product?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
                    <Button
                        onClick={() => {
                            try {
                                ApiService.delete("products", product.id);
                                setIsDeleteDialogOpen(false);
                                fetchData();
                            } catch (error) {
                                console.error(error);
                            }
                        }}
                        color="error"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ProductCard;